@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	h1 {
	  @apply text-2xl;
	}
	h2 {
	  @apply text-xl font-bold;
	}
	h3 {
	  @apply text-lg font-bold;
	}
	h4 {
	  @apply text-base font-bold;
	}

	ul {
		@apply list-disc pl-10;
	}
	ol {
		@apply list-decimal pl-10;
	}
}

.react-tooltip{
	z-index: 9999;
}
.planiation:hover button{
	opacity: 1;
	transition: 0.5s;
}

/* blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,4
p,
pre {
	margin: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
	display: block;
	vertical-align: middle;
}

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: theme('borderColor.DEFAULT', currentColor);
}

img,
video {
  max-width: 100%;
  height: auto;
} */


body {
	background-color: #ebebeb;
}

.modal-fade-in {
	animation-name: fade_in;
	animation-duration: 1s;
}

.menu-fade-in {
	animation-name: fade_in;
	animation-duration: 0.3s;
}

.modal-fade-out {
	animation-name: modal_fade_out;
	animation-duration: 0.5s;
}

@keyframes fade_in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes modal_fade_out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

/* STRIPE STYLES */
#payment-message {
	color: rgb(105, 115, 134);
	font-size: 16px;
	line-height: 20px;
	padding-top: 12px;
	text-align: center;
}

#payment-element {
	margin-bottom: 24px;
}

/* Buttons and links */
button.stripe-button {
	background: #5469d4;
	font-family: Arial, sans-serif;
	color: #ffffff;
	border-radius: 4px;
	border: 0;
	padding: 12px 16px;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;
	display: block;
	transition: all 0.2s ease;
	box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
	width: 100%;
}

button.stripe-button:hover {
	filter: contrast(115%);
}

button.stripe-button:disabled {
	opacity: 0.5;
	cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
	border-radius: 50%;
}

.spinner {
	color: #ffffff;
	font-size: 22px;
	text-indent: -99999px;
	margin: 0px auto;
	position: relative;
	width: 20px;
	height: 20px;
	box-shadow: inset 0 0 0 2px;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}

.spinner:before,
.spinner:after {
	position: absolute;
	content: '';
}

.spinner:before {
	width: 10.4px;
	height: 20.4px;
	background: #5469d4;
	border-radius: 20.4px 0 0 20.4px;
	top: -0.2px;
	left: -0.2px;
	-webkit-transform-origin: 10.4px 10.2px;
	transform-origin: 10.4px 10.2px;
	-webkit-animation: loading 2s infinite ease 1.5s;
	animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
	width: 10.4px;
	height: 10.2px;
	background: #5469d4;
	border-radius: 0 10.2px 10.2px 0;
	top: -0.1px;
	left: 10.2px;
	-webkit-transform-origin: 0px 10.2px;
	transform-origin: 0px 10.2px;
	-webkit-animation: loading 2s infinite ease;
	animation: loading 2s infinite ease;
}

.editor .ck-editor__editable_inline {
	min-height: calc(100vh - 290px);
	background: #ebebeb !important;
	border: none !important;
	box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
	border-radius: 0px 0px 10px 10px !important;
	transition: 0.5s;
}
.editor .ck-editor__editable_inline.ck-focused {
	background: #fff !important;
	transition: 0.5s;
}
.editor .ck-sticky-panel__content {
	border-radius: 10px 10px 0px 0px !important;
}
.editor .ck-toolbar {
	border-radius: 10px 10px 0px 0px !important;
	background: #333 !important;
}
.editor .ck-toolbar button {
	color: #fff !important;
	transition: 0.5s;
	cursor: pointer;
}
.editor .ck-toolbar button.ck-on {
	color: #333 !important;
	transition: 0.5s;
	cursor: pointer;
}
.editor .ck-toolbar ul button span.ck-button__label {
	color: #333 !important;
	transition: 0.5s;
	cursor: pointer;
}
.editor .ck-toolbar button:hover {
	color: #333 !important;
	transition: 0.5s;
}
.editor .ck-content{
	padding: 10px 50px !important;
}
.editor h2{
	margin-top: 30px;
}

@keyframes loading {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@media only screen and (max-width: 600px) {
	form {
		width: 80vw;
		min-width: initial;
	}
}
